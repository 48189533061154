































import { Component, Prop, Vue } from "vue-property-decorator";

import { Ts } from "@/common/test/ts";
// @ts-ignore
import VueDragResize from "vue-drag-resize";

interface Rect {
    width: number;
    height: number;
    top: number;
    left: number;
}

@Component({
    components: {
        VueDragResize: VueDragResize,
    },
})
export default class HelloWorld extends Vue {
    private width: number = 0;
    private height: number = 0;
    private top: number = 0;
    private left: number = 0;
    private audio = null
    private timer = null
    private resize(newRect: Rect) {
        console.log(newRect);
    }

    private percentage: number = 0;
    private testFile!: Ts;

    constructor() {
        super();
        this.testFile = new Ts();
    }

    private file: File | null = null;

    private getFile(e: Event): void {
        const fileElement = (<HTMLInputElement>e.target).files;
        this.file = fileElement ? fileElement[0] : null;
        if (this.file) {
            this.testFile.init(this.file);
        }
    }

    private stop(): void {
        this.testFile.stop();
    }

    private startUpload(): void {
        if (this.file) {
            this.testFile.start((hasLoadSize: number, allSize: number) => {
                this.percentage = Math.floor(
                    ((allSize - hasLoadSize) / allSize) * 100
                );
            });
        }
    }

    private created() {}
    private mounted() {
        this.audio = new Audio(require("@/assets/test.mp3"));

        // 设置定时器，5 秒后播放音频
        this.timer = setTimeout(() => {
            this.playAudio();
            console.log("play audio")
        }, 5000);
    }
    private playAudio() {
        if (this.audio) {
        this.audio.play();
      }
    }
}
