import {BasicsClass} from '../BasicsClass';
// @ts-ignore
import Worker from 'worker-loader!@/views/BasicFileListTs.worker';
// @ts-ignore
import Md5Worker from 'worker-loader!@/views/Md5Blob.worker';

import {AxiosError, AxiosResponse, CancelTokenSource} from 'axios';
import {AxiosData} from '@/common/http/http';
import Qs from 'qs';

interface BlobList {
    file: File;
    name: string;
}

interface CheckResult {
    name: string;
    exist: boolean;
}

type CallBackFunction = (hasUploadSize: number, allSize: number) => void;

export class Ts extends BasicsClass {

    private blobList: BlobList[] = [];
    private cancelTokenList: Map<number, CancelTokenSource>;

    private showMessage: boolean = true;
    private messageTimer: number = -1;
    private file!: File;

    private callBack: CallBackFunction | undefined;
    private allSize: number = 0;
    private fileMd5: string = '';

    constructor() {
        super();
        this.cancelTokenList = new Map<number, CancelTokenSource>();
    }

    public init(file: File) {
        this.file = file;
        this.sliceFile();
    }

    public sliceFile(): void {
        const chunk = 0;
        const chunkSize = 1024 * 1024 * 10;

        const BasicWorker = new Worker();
        const Md5File = new Md5Worker();
        const loading = this.vue.$loading({text: '加载中'});
        const loading1 = this.vue.$loading({text: '加载中'});

        Md5File.onmessage = (ev: MessageEvent) => {
            loading1.close();
            this.fileMd5 = ev.data;
            console.log(this.fileMd5);
        }

        Md5File.postMessage(this.file);

        BasicWorker.onmessage = (event: MessageEvent) => {
            loading.close();
            this.blobList = event.data;
            this.allSize = this.blobList.length;
            console.log(this.blobList);
        };

        BasicWorker.postMessage({
            file: this.file,
            chunk,
            chunkSize,
        });
    }

    public stop(): void {

        this.cancelTokenList.forEach(value => value.cancel(''));

        this.cancelTokenList.clear();
    }

    public start(callBack?: CallBackFunction): void {
        this.callBack = callBack;

        this.checkHasUploadFile();
    }

    private checkHasUploadFile(): void {
        this.vue.$axios.get('/upload-admin/upload/check', {
            params: {
                fileName: this.fileMd5,
            }
        }).then((data: AxiosResponse<AxiosData<CheckResult[]>>) => {

            const dataMap: Map<string, BlobList> = this.handleBlobList(data.data.data);
            if (dataMap.size) {
                this.blobList = [...dataMap.values()];
                console.log(this.blobList);
                this.postFileWithArray();
            } else {
                this.mix();
            }
        });
    }

    private handleBlobList(data: CheckResult[]): Map<string, BlobList> {
        let map = new Map();
        for (let i = 0; i < this.blobList.length; i++) {
            map.set(this.blobList[i].name, this.blobList[i]);
        }

        for (let i = 0; i < data.length; i++) {
            if (map.has(data[i].name)) {
                map.delete(data[i].name);
            }
        }
        return map;
    }

    private postFileWithArray(): void {
        this.cancelTokenList.clear();
        for (let i = 0; i < this.blobList.length; i++) {
            ((i, m) => {
                this.http(i, m);
            })(this.blobList[i], i);
        }
    }

    private http(blobList: BlobList, i: number): void {
        this.cancelTokenList.set(i, this.vue.$axios.CancelToken.source());
        const formData = new FormData();

        formData.append('file', blobList.file);
        formData.append('fileName', `${this.fileMd5}.${this.file.name.split('.')[1]}-${i}`);
        formData.append('md5', `${blobList.name}`);

        this.vue.$axios.post('/upload-admin/upload/upload', formData, {
            cancelToken: this.cancelTokenList.get(i)!.token,
        }).then((data: AxiosResponse<AxiosData<string>>) => {
            if (data.data.code === 200) {
                this.cancelTokenList.delete(i);
                console.log(this.cancelTokenList.size);
                this.callBack && this.callBack(this.cancelTokenList.size, this.allSize);

                if (!this.cancelTokenList.size) {
                    this.mix();
                }
            } else {
                this.errMsgToast(data.data.message);
            }
        }).catch((err: AxiosError) => {
            try {
                this.errMsgToast(err.response!.statusText);
            } catch {
                // do not thing
            }
        });
    }

    private errMsgToast(message: string): void {
        if (this.showMessage) {
            this.vue.$message.error(message);
            this.showMessage = false;
        } else {
            clearTimeout(this.messageTimer);
        }

        this.messageTimer = setTimeout(() => {
            this.showMessage = true;
        }, 3000);
    }

    private mix(): void {
        const params = {'fileName': this.fileMd5};
        this.vue.$axios.post('/upload-admin/upload/mix', Qs.stringify(params)).then((data: AxiosResponse<AxiosData<string>>) => {
            this.vue.$message.success(data.data.message);
        });
    }
}


